import React from 'react'

function Tnc() {
  return (
    <div>
      <div className="font-judul text-5xl bg-blue-primary text-white text-center py-4">
        <h1>FITNESSWORK TERM & CONDITION</h1>
      </div>
      <div className="container mx-auto py-10">
        <div className="px-4 md:px-8 pb-8">
          <ol className="">
            <li className="py-2">Anda wajib memenuhi batas usia (18 tahun atau lebih) ketika menyetujui perjanjian ini; jika dibawah 18 tahun Anda harus menyatakan bahwa Anda telah memperoleh persetujuan dari wali (orang tua) sebelum menyetujui perjanjian ini. Kartu identitas harus ditunjukkan setiap kali Anda memasuki Club. Data pribadi Anda akan diambil oleh FITNESSWORKS untuk kelengkapan data, marketing, maupun tujuan identifikasi. Dengan ini Anda menyetujui FITNESSWORKS untuk mengambil dan menggunakan data dan informasi pribadi Anda. Anda menyetujui bahwa kami dapat memberikan informasi Anda kepada penasehat professional kami jika diperlukan oleh hukum atau diperlukan untuk kepentingan penjualan dalam FITNESSWORKS atau penyelesaian masalah keluhan. Dengan menandatangani surat perjanjian ini, Anda menyetujui setiap dan seluruh peraturan dan pedoman keanggotaan yang disampaikan melalui club dan perjanjian ini, FITNESSWORKS berhak mengubah setiap dan seluruh peraturan dari waktu ke waktu sesuai kebijaksanaan kami terkait kemungkinan adanya perubahan dan atau penyesuaian kebijakan-kebijakan pemerintah setempat dan pertimbangan bisnis, setelah sekali didaftarkan, maka Anda setuju bahwa semua peraturan ini berlaku pada Anda.</li>
            <li className="py-2">Jika anda dibawah usia 16 tahun, diwajibkan memakai jasa Personal Trainer.</li>
            <li className="py-2">Mereka yang bukan anggota, termasuk anak kecil dibawah 12 tahun TIDAK diperkenankan untuk memasuki area gym. Mereka hanya diperkenankan untuk menunggu di lobby/ Kids Corner dengan satu orang dewasa sebagai pengawas.</li>
            <li className="py-2">Tarif biaya anggota tergantung pada jenis keanggotaan, tempat pembelian dan atau waktu pembelian. Tarif biaya keanggotaan Anda tertera dalam perjanjian ini, kecuali ada ketetapan lain sehubungan dengan tarif dan biaya keanggotaan sesuai dengan undang-undang setempat. Semua biaya keanggotaan dan pembayaran belum termasuk Pajak Jasa, Pajak Barang, Pajak Penambahan Nilai (PPN) maupun Pajak lain yang mungkin diberlakukan, sesuai peraturan yang berlaku.</li>
            <li className="py-2">Dalam hal keanggotaan PIF (Paid in Full/ keanggotaan dibayar dimuka dalam jangka waktu tertentu) dipilih sebagaimana tercantum pada bagian Detail Keanggotaan, anggota berhak untuk menggunakan fasilitas klub secara berkelanjutan selama jangka waktu PREPAID TERM. Setelah jangka waktu tersebut anggota dapat memilih untuk memperbaharui keanggotaannya sesuai dengan biaya biaya yang tercantum dalam jenis keanggotaan di masa yang akan datang. Segala Biaya pembayaran apapun yang telah dibayarkan tidak dapat dikembalikan (NON REFUNDABLE)</li>
            <li className="py-2">Dalam hal jenis keanggotaan EFT (Electronic Fund Transfer) dipilih sebagaimana tercantum pada bagian Detail Keanggotaan, anggota tersebut tidak dapat membatalkan perintah pembayaran melalui kartu kredit sampai jangka waktu minimum telah terlampaui anggota dapat mengakhiri keanggotaannya dengan memberitahukan secara tertulis dalam jangka waktu tidak kurang dari 7 hari kerja sebelum tanggal jatuh tempo pemotongan biaya keanggotaan berikutnya. Pemberitahuan tertulis harus diberikan secara langsung kepada Receptionist kami. Copy persetujuan pembatalan akan diberikan secara langsung kepada anggota sebagai bukti dari pengakhiran perjanjian. Dalam hal anggota memberikan pemberitahuan tertulis kurang dari 7 hari kerja sebelum tanggal jatuh tempo pemotongan biaya keanggotaan. Anggota akan tetap dibebankan biaya keanggotaan dan anggota tersebut berhak atas penggunaan fsilitas club untuk waktu 60 hari setelah tanggal jatuh tempo pembayaran. Anggota yang bermaksud mengakhiri keanggotaannya sebelum terpenuhinya jangka waktu minimum 12 bulan dapat melakukan hal tersebut dengan persetujuan Manager dengan biaya pengganti sebesar 20% dari jumlah biaya terhutang. Biaya ini tidak dapat diperhitungkan dengan biaya lain yang telah dibayar dimuka.</li>
            <li className="py-2">Seluruh biaya bulanan dan pembayaran yang jatuh tempo setiap bulannya harus dibayar dimuka melalui pemotongan langsung melalui kartu kredit/ Auto debit di kartu debit. Jika anggota lalai untuk membayar kewajiban pembayaran bulanan yang telah jatuh tempo, FITNESSWORKS berhak untuk menolak anggota tersebut untuk memasuki area FITNESSWORKS sampai pembayaran atas tunggakan diterima. FITNESSWORKS berhak untuk mengambil segala tindakan hukum untuk mendapatkan tunggakan pembayaran. Anggota harus menanggung seluruh biaya yang dikeluarkan oleh FITNESSWORKS berkaitan dengan tindakan hukum tersebut. Anggota tetap berkewajiban untuk membayar biaya keanggotaan dan pembayaran yang telah jatuh tempo selama periode tersebut.</li>
            <li className="py-2">Keanggotaan dengan metode pembayaran EFT (Electronic fund transfer) akan mengalami kenaikan sebesar 5%secara automatis dari harga membership awal setiap kelipatan 12 bulan berjalan.</li>
            <li className="py-2">Biaya keanggotaan EFT yang gagal debit dengan alasan apapun akan dikenakan biaya keterlambatan sebesar 10% dari biaya membership yang tertagihkan, berlaku kelipatan, dan berlaku system bunga ber bunga.</li>
            <li className="py-2">Dilarang keras melakukan pembayaran jasa ataupun barang apapun dari FITNESSWORKS melalui rekening lain selain rekening atas nama PT________ (nama PT Club terkait) FITNESSWORKS tidak akan bertanggung jawab atas semua bentuk pembayaran via transfer ke rekening dengan atas nama PT_______.</li>
            <li className="py-2">Jika salah satu club kami berhenti beroperasi, keanggotaan anda akan secara otomatis dipindahkan ke club kami yang terdekat.</li>
            <li className="py-2">Anggota harus memperlihatkan BARCODE keanggotaannya atau kartu identitasnya setiap kali datang ke FITNESSWORKS. Anggota wajib mendownload aplikasi Mobile App FITNESSWORKS untuk mengakses Barcode ini. Anggota yang tidak memiliki aplikasi tidak dapat masuk ke area FITNESSWORKS.</li>
            <li className="py-2">Anggota diijinkan untuk membekukan (freezing) membershipnya apabila tidak bisa datang ke club untuk jangka waktu lebih dari 1(satu) bulan dengan biaya pembekuan sebesar Rp. 100,000,- per bulan untuk membership reguler, dan 200,000,-/ bulan untuk membership Valor/Falcon/ PT Bundling dengan maksimum total pembekuan adalah 12 bulan maksimal 4 kali dalam setahun. Proses pembekuan untuk membership EFT harus diajukan secara tertulis dilalukan di customer service desk atau melalui Mobile App FITNESSWORKS selambat lambat nya 10 hari sebelum tanggal jatuh tempo pendebetan berikut nya. Proses cuti membership juga akan membekukan expired date sesi personal trainer yang dimiliki. Selama masa Cuti member dilarang untuk datang dan menggunakan fasilitas Club dengan alasan apapun. Apabila di dapatkan member tetap datang maka FITNESSWORKS berhak untuk membatalkan sepihak proses cuti nya dan tidak ada pengembalian dana atas alasan apapun. Untuk periode cuti melebihi ketentuan yang di telah tetapkan member harus menyertakan supporting document surat Dokter, surat dinas kerja, keterangan hamil dll. Yang menyebabkan member tidak dapat beraktifitas olahraga dalam waktu yang lama.</li>
            <li className="py-2">Keanggotaan tidak dapat dipakai oleh orang lain, ayau dipinjamkan ke kerabat dekat. Pelanggaran atas poin ini akan mengakibatkan pelarangan akses masuk secara sepihak sampai anggota datang mengadakan re aktivasi dengan membayar denda sebesar Rp. 200,000,- rupiah</li>
            <li className="py-2">Anggota berhak mentransfer membershipnya (1) satu kali saja ke kerabat atau rekannya. Setelah dipindah tangankan anggota lama tidak boleh menggunakan fasilitas FITNESSWORKS lagi ataupun menerima transfer dari membership dan orang yang sama. Proses transfer membership ini hanya bisa dilakukan 1(Satu) kali saja. Orang yang sudah menerima transfer membership dari orang lain tidak dapat mentransfer kan kembali membership nya ke orang ketiga. Biaya untuk satu kali transfer membership adalah Rp. 250,000,-. Proses transfer membership juga automatis akan men transfer semua sesi Personal trainer yang pernah dibeli oleh member sebelum nya. Transfer membership dari member sat uke member lain nya diperbolehkan dengan biaya Rp. 500,000,-. Dan setiap membership hanya bisa menerima 1x transfer dari member lain.</li>
            <li className="py-2">
              Peraturan perihal Personal Training
              <ul className="list-disc pl-4">
                <li className="py-1">Sesi PT yang tersisa setelah melewati masa expiry tidak dapat di gunakan akan di anggap hangus.</li>
                <li className="py-1">Masa berlaku sesi Personal Training tidak dapat diperpanjang, dalam situasi khusus untuk perpanjangan masa berlaku paket sesi harus persetujuan oleh management FITNESSWORKS.</li>
                <li className="py-1">Untuk kenyamanan jadwal latihan diharuskan untuk membuat janji dengan Personal Trainer dalam waktu minimun 24 jam sebelumnya melalui Mobile Apps FITNESSWORKS.</li>
                <li className="py-1">Jika karena suatu hal client berhalangan hadir dan ingin membatalkan sesi, member wajib membatalkan sesi nya melalui Mobile Apps maksimal 24 jam sebelumnya. Jika tidak ada pemberitahuan dalam waktu tesebut, sesi akan tetap dianggap telah dijalankan.</li>
                <li className="py-1">Verifikasi harus di lakukan dengan Customer Service setelah selesai sesi, menggunakan alat Finger Print Scanner.</li>
              </ul>
            </li>
            <li className="py-2">Apabila hendak mentransfer sesi Personal trainer ke orang lain hendak nya dilakukan minimal 1 bulan sebelum proses transfer membership terjadi dengan biaya Rp. 250,000,-</li>
            <li className="py-2">
              Peraturan Perihal Kelas
              <ul className="list-disc pl-4">
                <li className="py-1">
                  Booking Kelas
                  <ol className="list-decimal pl-4">
                    <li className="py-1">Semua Member yang ingin mengikuti kelas wajib melakukan Class Booking dulu via FITNESSWORKS Mobile App dan melalukan Check in di Front Desk untuk konfirmasi kedatangan selambat lambat nya 15 menit sebelum kelas dimulai. Member yang tidak datang 15 menit sebelum kelas dimulai akan kehilangan slot nya dan akan masuk ke waiting list berikut nya.Member bisa start booking Kelas mulai dari 6 jam sebelum kelas dimulai (selama slot masih available)</li>
                    <li className="py-1">Batas maximal untuk bisa booking Kelas yaitu 1 jam sebelum jadwal Kelas di mulai</li>
                    <li className="py-1">Member All Club hanya bisa booking Kelas di 1 lokasi Club yang sama sesuai Kelas yang dipilih pada hari itu (Cth: Jam 17:00 booking Kelas di Merr, maka jika ingin booking Kelas di hari yang sama hanya bisa di Merr, kecuali Booking Kelas pagi di Merr namun sore nya bisa Booking Kelas di lokasi lain)</li>
                  </ol>
                </li>
                <li className="py-1">
                  Cancel Kelas
                  <ol className="list-decimal pl-4">
                    <li className="py-1">Member yang sudah melakukan Booking Kelas tetapi melakukan Cancel Kelas kurang dari 1 jam sebelum Kelas dimulai atau NO SHOW akan diberikan Punishment berupa blacklist selama 1 minggu sehingga tidak bisa melakukan Booking Kelas</li>
                    <li className="py-1">Member yang sudah melakukan Booking Kelas tetapi melakukan Cancel Kelas kurang dari 1 jam sebelum Kelas dimulai atau NO SHOW akan diberikan Punishment berupa blacklist selama 1 minggu sehingga tidak bisa melakukan Booking Kelas</li>
                    <li className="py-1">Member yang sudah melakukan Booking Kelas tetapi melakukan Cancel Kelas kurang dari 1 jam sebelum Kelas dimulai atau NO SHOW akan diberikan Punishment berupa blacklist selama 1 minggu sehingga tidak bisa melakukan Booking Kelas</li>
                  </ol>
                </li>
              </ul>
            </li>
            <li className="py-2">Untuk melindungi kepentingan bisnisnya dan demi keselamatan dan pertimbangan para anggota lainnya, FITNESSWORKS berhak memutuskan keanggotaan setiap anggota dengan atau tanpa pemberitahuan terlebih dahulu. Alasan pemutusan dapat berupa perilaku yang dianggap tidak pantas ataupun tindakan yang menimbulkan kegaduhan atau menjadikan suatu keadaan menjadi tidak kondusif, termasuk tetapi tidak terbatas juga terhadap setiap tindakan kekerasan terhadap anggota lainnya atau staff FITNESSWORKS, perbuatan melawan hukum serta dugaan tindak pidana, atau melakukan pelayanan seperti kepelatihan pribadi (melatih jasa personal trainer). Penjualan barang-barang tanpa izin, perolehan finansial dan atau perilaku yang dianggap melanggar syarat dan ketentuan yang ditetapkan dalam perjanjian ini atau setiap peraturan dan ketentuan yang tertera di seluruh club. Semua uang yang telah dibayarkan tidak dapat dikembalikan. Sebagai tujuan proses pemutusan perjanjian keanggotaan ini, kedua belah pihak setuju untuk mengabaikan semua biaya yang terjadi selama keputusan untuk mengakhiri perjanjian ini. Anggota setuju dan sepakat mengesampingkan ketetapan ketentuan pasal 1266 dan 1267 kitab Undang-Undang Hukum Perdata Republik Indonesia sehubungan dengan pengakhiran perjanjian keanggotaan.</li>
            <li className="py-2">Anggota HARUS menggunakan sepatu dan pakaian olah raga yang layak. Anggota yang tidak memakai sepatu ataupun pakaian olah raga yang layak (memakain gaju dan sepatu olah raga) TIDAK DIPERKENANKAN masuk dan olah raga di dalam Gym. Anggota juga berkewajiban untuk memelihara semua properti di dalam Gym. Tidak diperkennkan untuk membanting beban secara tidak wajar, dan harus meletakkan kembali alat alat yang telah dipakai ke tempat semula. pelanggaran akan hal ini akan dikenakan surat peringatan tertulis, dan bila terulang setelah adanya surat peringatan akan mengakibatkan terminasi sepihak dari Pihak FITNESSWORKS, dan tidak ada pengembalian uang dalam bentuk apapun.</li>
            <li className="py-2">Anggota wajib berhati hati dan mengganti segala macam bentuk kerusakan yang tidak wajar yang ditimbulkan karena kelalaian maupun kesengajaan sejumlah nilai beli barang tersebut di waktu kerusakan tersebut terjadi, atau menggantinya dengan barang yang sama persis. Adapun bila barang yang dimaksud sudah tidak diproduksi lagi, maka jumlah kerugian yang dibayarkan adalah sejumlah faktur pembelian barang tersebut semulajadi. Keputusan ini adalah mutlak dan tidak dapat di ganggu gugat.</li>
            <li className="py-2">Anda diminta untuk memberikan data pribadi yang berlaku, seperti: nomor telepon, alamat, alamat email, dan nomor telepon darurat yang bisa di hubungi seperti yang telah ditentukan dibagian “Profil Anggota”. FITNESSWORKS dapat mengirimkan pemberitahuan kepada anda dari waktu ke waktu tentang status keanggotaan, promosi, event. Dalam hal anggota mengalami perubahan data, maka anggota wajib untuk memberitahukan perubahan datanya tersebut.</li>
            <li className="py-2">Apabila Mobile Apps ada kendala, Anda harus menunjukan kartu IDENTITAS/keanggotaan anda untuk masuk ke area FITNESSWORKS. Jika kartu anda hilang atau dicuri, anda wajib menghubungi club untuk menerbitkannya kembali kartu pengganti. Anda bertanggung jawab atas biaya penggantian kartu.</li>
            <li className="py-2">Anggota wajib menjaga barang bawaan yang berharga seperti perhiasan, HP, dan lain-lain. Dikarenakan segala bentuk kehilangan kerusakan barang pribadi anggota bukan menjadi tanggung jawab FITNESSWORKS</li>
            <li className="py-2">Kelas -Kelas pribadi dan pelatihan pribadi seperti personal training akan dikenakan biaya tambahan dan tidak termasuk biaya keanggotaan.</li>
            <li className="py-2">Tanpa adanya pemberitahuan atau persetujuan terlebih dahulu dari FITNESSWORKS, kamera atau alat perekam foto dan video lainnya termasuk telepon genggam yang memiliki alat perekam seperti yang dimaksud tersebut, dilarang digunakan untuk pengambilan gambar atau rekaman di area ruang ganti</li>
            <li className="py-2">Untuk alasan keamanan dan melindungi para anggota dan karyawan dalam lokasi kami diawasi oleh kamera pengawas (terkecuali ruang ganti). Anda tidak disarankan untuk meninggalkan barang berharga anda diruang ganti. FITNESSWORKS memiliki loker pengamanan yang terletak dari luar ruang ganti dan diawasi oleh kamera pengawas. FITNESSWORKS tidak bertanggung jawab atas kehilangan barang diseluruh area club.</li>
            <li className="py-2">Kewajiban lain yang tidak tertera dalam perjanjian ini yang kemungkinan akan timbul, akan mengikuti peraturan dan Undang-Undang yang berlaku di Indonesia.</li>
            <li className="py-2">Semua perselisihan yang timbul dari atau sehubungan dengan perjanjian ini akan diupayakan secara kekeluargaan. Dalam hal ini penyelesaian secara kekeluargaan tidak dapat tercapai, maka masing-masing pihak sepakat menunjuk Badan Arbitrase Nasional (BAN) guna penyelesaian hukum atas perselisihan.</li>
            <li className="py-2">Anggota berhak atas loker harian yang dapat digunakan untuk menyimpan barang-barang anggota. Loker harian adalah loker dengan ukuran cukup besar dan harus dikosongkan pada saat anggota selesai melakukan olah raga pada hari yangg sama. Kami tidak menyediakan kunci untuk tiap loker, untuk alasan keselamatan anggota disarankan untuk membawa kunci pad lock sendiri yang bisa dipasangkan di tiap loker kami. Barang yang tidak diambil pada saat berakhir nya jam operasional akan kami amankan dan pad lock akan kami buka dengan paksa. barang yang berada pada loker harian akan disimpan untuk jangka waktu paling lama 14 Hari, setelah itu barang barang tersebut akan disumbangkan kepada badan amal. Dalam hal pengambilan barang tersebut anggota akan dibebankan biaya sebesar Rp. 50,000,- sebagai biaya penyimpanan. Kunci beserta isi dari locker tersebut tidak dapat dimintakan penggantiannya. Locker sepatu (permanent locker) juga tersedia dan dapat disewa untuk jangka waktu tertentu. Untuk detailnya dapat ditanyakan kepada petugas receptionist.</li>
            <li className="py-2">Untuk menjaga privacy dan kenyamanan anggota lain, penggunaan Locker harian, shower dan ruang ganti Pria dan Wanita hanya diperuntukkan bagi anggota yang memiliki penampilan fisik yang sama dengan jenis kelamin yang terdapat di KTP resmi yang di daftarkan ke Customer service, anggota yang mempunyai penampilan fisik yang berbeda dengan jenis kelamin yang tertera di KTP tidak diperkenankan menggunakan Locker, Shower maupun ruang ganti di area FITNESSWORKS. Penggunaan Ruang ganti bisa dilakukan di area lain dalam gedung diluar area FITNESSWORKS</li>
            <li className="py-2">Anggota dengan ini menyadari, menyatakan dan menjamin bahwa penggunaan fasilitas di FITNESSWORKS tentunya mengandung resiko kecelakaan bagi setiap anggota sendiri, atau anggota lainnya, atau orang disekitarnya, baik disebabkan oleh anda sendiri maupun orang lain. Anggota memahami dan secara sukarela menerima resiko tersebut. Anggota menjamin dan menyatakan bahwa anggota telah berkonsultasi dengan dokter sebelum memulai setiap latihan, sehingga dari dan oleh karenanya, anggota dengan ini setuju dan membebaskan tanggung jawab FITNESSWORKS atas segala jenis kecelakaan, termasuk tidak terbatas pada cedera pribadi, cedera fisik, cedera mental, kerugian ekonomi, atau kerugian lain anggota, atau keluarganya sebagai dari tindakan seseorang yang menggunakan fasilitas ataupun tindakan dari pegawai atau agen FITNESSWORKS.</li>
            <li className="py-2">Anggota setuju bertanggung jawab sepenuhnya atas semua tanggung jawab dan kerugian yang timbul sebagai akibat dari setiap kecelakaan, termasuk namun tidak terbatas pada cedera tubuh atau cedera mental, kerugian ekonomi, atau setiap kerugian bagi anggota lain disebabkan oleh tindakan kesengajaan atau kelalaian anggota sendiri. Jika ada tuntutan dari siapapun juga yang dikarenakan oleh cedera apapun, kehilangan atau kerusakan lainnya yang berkaitan dengan anda atau tamu anda maka anda menyetujui untuk (I) membela FITNESSWORKS atas segala tuntutan tersebut dan membayar FITNESSWORKS atas segala pengeluaran termasuk biaya hukum berkaitan dengan tuntutan tersebut. (II) melindungi FITNESSWORKS dari segala tanggung jawab kepada anda, suami/istri anda, anak dalam kandungan, keluarga atau siapapun juga sebagai akibat yang terkait dari tuntutan tersebut dan (III) membebaskan FITNESSWORKS dari segala bentuk tuntutan, gugatan dang anti kerugian dalam bentuk apapun dan dari siapapun tanpa terkecuali.</li>
            <li className="py-2">Dengan ini anggota menyadari bahwa FITNESSWORKS tidak pernah menjanjikan apapun selain apa yang disampaikan secara tertulis oleh FITNESSWORKS dan atau sebagaimana yang tertulis pada perjanjian ini, dalam hal ini anggota mendapatkan janji-janji diluar perjanjian ini, misal oleh staff FITNESSWORKS (oknum) maka anggota dapat melaporkan atau menyampaikan kepada pihak FITNESSWORKS, namun TETAP janji-janji tersebut tidak berlaku dan tidak mengikat kedua belah pihak dan anggota dengan ini membebaskan FITNESSWORKS dari segala bentuk tuntutan, gugatan, dan mengganti kerugian dalam bentuk apapun dan dari siapapun tanpa terkecuali.</li>
            <li className="py-2">Anggota dengan ini menyatakan dan menjamin bahwa isi dan perjanjian mencakup segala komunikasi dan atau pernyataan diantara anggota dan FITNESSWORKS. Sehubungan dengan hal-hal yang berkaitan dengan perjanjian ini, sehingga segala bentuk komunikasi dan atau pernyataan diantara anggota dan FITNESSWORKS yang tidak diatur dalam perjanjian ini menjadi tidak berlaku dan tidak mengikat kedua belah pihak, dan anggota dengan ini membebaskan FITNESSWORKS dari segala bentuk tuntutan, gugatan, dan kerugian dalam bentuk apapun dan dari siapapun tanpa terkecuali.</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Tnc